import React, { FC, ReactNode, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { removeZeroesAfterComma } from 'helpers';
import { useClickOutside } from 'hooks';
import { Button, InputNumber, Typography } from 'ui-kit';

import { getUserGeo, makeDeposit } from '../../../../api';
import { Modal } from '../Modal';
import { useStyle } from './ManualDeposit.styles';

interface IManualDepositProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ManualDeposit: FC<IManualDepositProps> = ({ isOpen, onClose }) => {
  const ref = useRef(null);
  useClickOutside(ref, onClose);
  const { classes } = useStyle();
  const [amountGc, setAmountGc] = useState<number>(0);
  const [error, setError] = useState<string>('');

  const { Headline, Text } = Typography;
  const { data: transactions } = useSWR('api/transactions');
  const { t: translation } = useTranslation();
  const onDepositClick = async () => {
    const response = await makeDeposit(amountGc);
    if (response) {
      window.location.href = response;
    }
  };

  const { data: geo } = useSWR('api/geo', getUserGeo);

  const ModalContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
    if (geo?.country === 'RU') {
      return (
        <div ref={ref} className={classes.modalContainer}>
          <Headline className={classes.title} block>
            {translation('profile.depositModal.title')}
          </Headline>
          <div>
            <Text className={classes.textWarning} variant="b5" block>
              В данный момент вывод средств через форму на карты РФ и Белоруссии
              <Text variant="b5" color="alert">
                {' '}
                недоступен.
              </Text>
              <br /> Если вы хотите осуществить вывод через форму то включите
              VPN и воспользуйтесь картами зарубежных банков.
            </Text>
          </div>
          {children}
          <Text className={classes.textWarning} variant="b5" block>
            Если же вы хотите вывести средства именно через карты РФ то
            обратитесь к нам в{' '}
            <a href="https://discord.gg/7fKEWhnh4x">
              <Text variant="b5" color="link">
                Discord
              </Text>
            </a>{' '}
            в соответствующий раздел. Мы работаем над устранением данной
            проблемы и в ближайшее время она будет решена.
          </Text>
        </div>
      );
    }

    return <div ref={ref}>{children}</div>;
  };

  const Form: React.FC = () => {
    return (
      <div className={classes.form}>
        {geo?.country !== 'RU' && (
          <Headline className={classes.title} variant="h3">
            {translation('profile.depositModal.title')}
          </Headline>
        )}
        <Text block variant="b5" className={classes.description}>
          {translation('profile.depositModal.description')}{' '}
          <a>
            <Text variant="b5" color="link">
              Discord
            </Text>
          </a>
        </Text>
        {geo?.country !== 'RU' && transactions?.count === 0 && (
          <div className={classes.promoBanner}>
            <Headline variant="h2" block>
              + 10%
            </Headline>
            <Text block variant="b4">
              {translation('profile.depositModal.promo')}
            </Text>
          </div>
        )}

        <div className={classes.inputContainer}>
          <InputNumber
            title="GC"
            placeholder="2000 GC"
            suffix=" GC"
            className={classes.input}
            value={amountGc}
            decimalScale={2}
            onValueChange={({ value }) => {
              setAmountGc(+value);
            }}
            onBlur={() => {
              if (amountGc < 2000) {
                setError('Minimum 2000 GC');
              } else {
                setError('');
              }
            }}
            error={error}
          />
          <Text variant="b5" block>
            =
          </Text>
          <InputNumber
            title="USD"
            placeholder="10 USD"
            suffix=" USD"
            decimalScale={2}
            className={classes.input}
            value={amountGc / 1000}
            onValueChange={({ value }) => {
              setAmountGc(+value * 1000);
            }}
            onBlur={() => {
              if (amountGc < 2000) {
                setError('Minimum 2000 GC');
              } else {
                setError('');
              }
            }}
          />
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.taxContainer}>
            {transactions?.count === 0 && (
              <div className={classes.taxDataContainer}>
                <Text variant="b6" block color="typoLabel">
                  {translation('profile.depositModal.bonus')}
                </Text>
                <Text block variant="b5">
                  {`+ ${removeZeroesAfterComma(
                    (amountGc * 0.1).toFixed(2)
                  )} GC`}
                </Text>
              </div>
            )}
            <div className={classes.taxDataContainer}>
              <Text variant="b6" block color="typoLabel">
                {translation('profile.depositModal.fee')}
              </Text>
              <Text block variant="b5">
                {`+ $${removeZeroesAfterComma(
                  (amountGc * 0.000025).toFixed(2)
                )}`}
              </Text>
            </div>
            <div className={classes.taxDataContainer}>
              <Text variant="b6" block color="typoLabel">
                {translation('profile.depositModal.tax')}
              </Text>
              <Text block variant="b5">
                {`+ $${removeZeroesAfterComma(
                  (amountGc * 0.00003).toFixed(2)
                )}`}
              </Text>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.buyBtn}
              disabled={!!error && amountGc >= 2000}
              onClick={onDepositClick}
            >{`${translation('profile.depositModal.btn')} $${(
              Math.floor((amountGc / 10) * 1.055) / 100
            ).toFixed(2)}`}</Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContainer>
        <Form />
      </ModalContainer>
    </Modal>
  );
};
